<template>
  <WeContainer card="" v-if="ready">
    <div>
      <Navigation
        v-on:save-form="submitForm"
        v-bind:submit="submit"
        v-bind:errors="errors"
      />
    </div>
    <div class="tab-content">
      <div class="tab-pane fade show active">
        <div class="row align-items-stretch mb-xl-3">
          <div class="col-12 mb-3 col-xl mb-xl-0">
            <div class="card h-100">
              <div class="card-body">
                <div class="row">
                  <!-- Name -->
                  <div class="col-12 col-md-6">
                    <WeInput
                      label="Ad"
                      name="first_name"
                      placeholder="Ad"
                      v-bind:required="true"
                      v-model="firstName"
                      v-bind:error="$v.firstName.$error"
                    />
                  </div>
                  <!-- ./Name -->

                  <!-- Last Name -->
                  <div class="col-12 col-md-6">
                    <WeInput
                      label="Soyad"
                      name="last_name"
                      placeholder="Soyad"
                      v-bind:required="true"
                      v-model="lastName"
                      v-bind:error="$v.lastName.$error"
                    />
                  </div>
                  <!-- ./Last Name -->

                  <!-- Phone Number -->
                  <div class="col-12 col-md-6 mb-3 mb-md-0">
                    <WePhone v-bind:label="$t('gsm')" v-model="data.phone" />
                  </div>
                  <!-- ./Phone Number -->

                  <!-- Role Group -->
                  <div class="col-12 col-md-6">
                    <div class="form-group">
                      <label for="role-group" class="custom-label"
                        >Kullanıcı Rolü</label
                      >
                      <select
                        id="role-group"
                        class="custom-select"
                        v-model="data.role_group_id"
                      >
                        <option
                          v-for="role in shared.role.roleGroups"
                          v-bind:key="role.id"
                          v-bind:value="role.id"
                          v-bind:selected="true"
                        >
                          {{ role.name }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <!-- Role Group -->

                  <!-- Email -->
                  <div class="col-12">
                    <WeInput
                      label="E-Posta"
                      name="email"
                      placeholder="E-Posta Adresi"
                      v-bind:required="true"
                      v-model="userEmail"
                      v-bind:error="$v.userEmail.$error"
                    />
                  </div>
                  <!-- ./Email -->

                  <!-- Password -->
                  <div class="col-12 col-md-6">
                    <WeInput
                      label="Şifre"
                      name="password"
                      type="password"
                      placeholder="Şifre"
                      v-bind:required="true"
                      v-model="userPassword"
                      v-bind:error="$v.userPassword.$error"
                    />
                  </div>
                  <!-- ./Password -->

                  <!-- Password Confirmation -->
                  <div class="col-12 col-md-6">
                    <WeInput
                      label="Şifre Tekrar"
                      name="password-confirmation"
                      type="password"
                      placeholder="Şifre Tekrar"
                      v-bind:required="true"
                      v-model="passwordConfirmation"
                      v-bind:error="$v.passwordConfirmation.$error"
                    />
                  </div>
                  <!-- ./Password Confirmation -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Submit -->
  </WeContainer>
  <WeLoading v-else />
</template>
<script>
import { mapActions, mapMutations, mapState } from "vuex";
import { required, requiredIf, email } from "vuelidate/lib/validators";
const Navigation = () => import("./views/Navigation/Index");

export default {
  name: "Detail",
  data() {
    return {
      data: {
        first_name: null,
        last_name: null,
        phone: null,
        email: null,
        password: null,
        password_confirmation: null,
        role_group_id: 1,
      },
      submit: false,
      ready: false,
      errors: [],
      cleanData: {},
      cleanRequest: {},
    };
  },
  validations: {
    firstName: {
      required,
    },
    lastName: {
      required,
    },
    userEmail: {
      required,
    },
    userPassword: {
      required: requiredIf(function (data) {
        return !this.$route.params.id;
      }),
    },
    passwordConfirmation: {
      required: requiredIf(function (data) {
        return !this.$route.params.id;
      }),
    },
  },
  components: { Navigation },
  methods: {
    ...mapActions("user", ["create", "update", "load", "getById"]),
    ...mapMutations("shared", ["setMessage", "clearMessage"]),
    getUserById() {
      if (this.$route.params.id) {
        this.getById({
          id: this.$route.params.id,
          onSuccess: (result) => {
            let item =
              result && result.data && result.data.item ? result.data.item : {};
            if (item) {
              item.role_group_id = item.role_group_id
                ? item.role_group_id.id
                : null;
            }
            helper.copy(item, this.data);
            this.cleanData = helper.clone(this.data);
          },
          onFinish: () => {
            this.ready = true;
          },
        });
      } else {
        this.ready = true;
      }
    },
    validateForm() {
      this.$v.$touch();

      if (this.$v.$invalid) {
        this.$swal({
          title: "Hata",
          text: "Lütfen zorunlu alanları doldurun",
          icon: "error",
        });

        return false;
      } else {
        return true;
      }
    },
    submitForm() {
      if (!this.validateForm()) {
        return;
      }
      this.submit = true;

      if (this.$route.params.id) {
        this.cleanRequest = helper.difference(this.data, this.cleanData);
        this.updateUser();
      } else {
        this.addUser();
      }
    },
    addUser() {
      this.create({
        form: this.data,
        onSuccess: (result) => {
          if (result && result.data && result.data.status !== "error") {
            this.$swal({
              title: "İşlem Başarılı",
              text: "Kullanıcılar listesine dönebilir veya bu sayfada kalabilirsiniz.",
              icon: "success",
              showCancelButton: true,
              cancelButtonText: "Detaya Git",
              confirmButtonText: "Liste Sayfasına Dön",
            }).then((confirm) => {
              if (confirm.isConfirmed) {
                this.redirectToList();
              } else {
                this.$router.push(`detail/${result.data.id}`);
              }
            });
          } else {
            this.showAlert(result);
          }
        },
        onFinish: () => {
          this.submit = false;
        },
      });
    },
    updateUser() {
      const userId = this.$route.params.id;
      this.update({
        id: userId,
        form: this.cleanRequest,
        onSuccess: (result) => {
          if (result && result.data && result.data.status !== "error") {
            this.$swal({
              title: "İşlem Başarılı",
              text: "Kullanıcılar listesine dönebilir veya bu sayfada kalabilirsiniz.",
              icon: "success",
              showCancelButton: true,
              cancelButtonText: "Sayfada Kal",
              confirmButtonText: "Liste Sayfasına Dön",
            }).then((confirm) => {
              if (confirm.isConfirmed) {
                this.redirectToList();
              } else {
                window.location.reload();
              }
            });
          } else {
            this.showAlert(result);
          }
        },
        onFinish: () => {
          this.submit = false;
        },
      });
    },
    updateLocaleUser(data) {
      let sessionStorage = JSON.parse(localStorage.session);
      this.session.userFullName = data.full_name;
      sessionStorage.userFullName = data.full_name;
      localStorage.setItem("session", JSON.stringify(sessionStorage));
    },
    redirectToList() {
      this.$router.push("/users");
    },
    showAlert(result, callback) {
      this.setMessage(result);
      this.$swal(this.shared.message).then((confirm) => {
        if (callback && confirm.isConfirmed) callback();
      });
      this.clearMessage();
    },
  },
  computed: {
    ...mapState(["user", "shared", "session"]),
    getTitle() {
      return this.$route.params.id ? "KULLANICI DÜZENLE" : "KULLANICI EKLE";
    },
    checkPermission() {
      return permission.check("user", "u");
    },
    firstName: {
      get() {
        return this.data.first_name;
      },
      set(value) {
        this.data.first_name = value;
      },
    },
    lastName: {
      get() {
        return this.data.last_name;
      },
      set(value) {
        this.data.last_name = value;
      },
    },
    userEmail: {
      get() {
        return this.data.email;
      },
      set(value) {
        this.data.email = value;
      },
    },
    userPassword: {
      get() {
        return this.data.password;
      },
      set(value) {
        this.data.password = value;
      },
    },
    passwordConfirmation: {
      get() {
        return this.data.password_confirmation;
      },
      set(value) {
        this.data.password_confirmation = value;
      },
    },
  },
  mounted() {
    this.load();
    this.getUserById();
  },
};
</script>
